<template>
   <ObiText class="obi-pe">
      <ObiText class="obi-pe-pane pane-configuration">
         <ObiText class="pane-header">
            <ObiTabBar class="--for-portal-editor">
               <template #tabs>
                  <ObiTabs :items="tabItems" v-model="selectedTab" @tabChanged="onTabChanged" />
               </template>
            </ObiTabBar>
         </ObiText>

         <ObiText v-if="!isLoading" class="pane-body">
            <ObiPortalGeneralTab
               v-if="selectedTab === 'portals-edit' && portalOptions"
               :portal-id="portalId"
               :options="portalOptions"
               @contentChanged="onContentChanged"
               @portalStyleChanged="onStyleChanged"
            />
            <ObiPortalProviderMethod
               v-if="selectedTab === 'portals-edit-sign-in-methods' && portalProviderOptions"
               :portal-id="portalId"
               :options="portalProviderOptions"
               @portalStyleChanged="onStyleChanged"
               @contentChanged="onContentChanged"
               @providerChanged="onProviderChanged"
            />
            <PortalSurveys
               v-if="selectedTab === 'portals-edit-surveys'"
               :portal-id="portalId"
               :surveys="formDataSurveys"
               @change="surveysChanged"
            />
            <PortalRules v-if="selectedTab === 'portals-edit-rules'" :portal-id="portalId" />
            <PortalEditAdvertisements v-if="selectedTab === 'portals-edit-advertisements'" :portal-id="portalId" />

            <PortalEditAdvanced
               v-if="selectedTab === 'portals-edit-advanced'"
               :portal-id="portalId"
               :options="portalOptions"
               :agreements="agreements"
               @contentChanged="onContentChanged"
               @agreementChanged="onAgreementChanged"
            />
         </ObiText>
         <ObiLoading v-else />
      </ObiText>
      <ObiText class="obi-pe-pane pane-preview">
         <ObiText class="pane-header">
            <ObiButtonGroup class="pane-switcher">
               <ObiButton
                  outline
                  color="lighter"
                  :icon="IconMobile"
                  :active="isPreviewType('mobile')"
                  @click="setPreviewType('mobile')"
               />
               <ObiButton
                  outline
                  color="lighter"
                  :icon="IconDesktop"
                  :active="isPreviewType('desktop')"
                  @click="setPreviewType('desktop')"
               />
            </ObiButtonGroup>

            <ObiText class="pane-portal-name">
               <ObiInput clean compact readonly :icon="IconWifi" v-model="formDataPortal.name" />
            </ObiText>

            <ObiText class="pane-actions">
               <ObiButton :text="$t('actions.save')" @click="submitPortalPut" />
            </ObiText>
         </ObiText>
         <ObiText class="pane-body" id="iframe-container">
            <iframe
               onload="this.width=document.getElementById('iframe-container').offsetWidth;"
               id="previewFrame"
               ref="previewFrame"
               :style="{ 'max-width': selectedPreviewType === 'mobile' ? '460px' : '100%' }"
               :src="previewAppUrl"
               height="100%"
            />
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { each } from 'lodash';
import { getSubdomain } from '@/libs/url-utils';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import PanelPortalApi from '@/api/PanelPortalApi';

import IconWifi from '@/components/Icons/general/IconWifi.svg';
import IconMobile from '@/components/Icons/general/IconMobile.svg';
import IconDesktop from '@/components/Icons/general/IconDesktop.svg';

import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';

import formDataPortal from '@/views/pages/Portal/FormData/form-data-portal';

import ObiPortalGeneralTab from '@/components/Common/ObiPortalGeneralTab';
import ObiPortalProviderMethod from '@/components/Common/ObiPortalProviderMethod';

import PortalRules from '@/views/pages/Portal/Edit/Rules';
import PortalSurveys from '@/views/pages/Portal/Edit/Surveys';
import PortalEditAdvertisements from '@/views/pages/Portal/Edit/Advertisements';
import PortalEditAdvanced from '@/views/pages/Portal/Edit/Advanced';

export default {
   name: 'ObiPortalEditor',

   components: {
      ObiPortalGeneralTab,
      ObiPortalProviderMethod,
      PortalSurveys,
      PortalRules,
      PortalEditAdvertisements,
      PortalEditAdvanced,
   },

   i18n: {
      messages: {
         tr: {
            actions: {
               save: 'Kaydet',
               publish: 'Yayınla',
            },
            'Giriş Metodları': 'Giriş Metodları',
            Anketler: 'Anketler',
            Reklamlar: 'Reklamlar',
            Kurallar: 'Kurallar',
            Gelişmiş: 'Gelişmiş',
            Genel: 'Genel',
         },
         en: {
            actions: {
               save: 'Save',
               publish: 'Publish',
            },
            'Giriş Metodları': 'Login Methods',
            Anketler: 'Surveys',
            Reklamlar: 'Ads',
            Kurallar: 'Rules',
            Gelişmiş: 'Advanced',
            Genel: 'General',
         },
      },
   },

   props: {
      portalId: {
         type: [String, Number],
         required: true,
      },
   },

   data() {
      return {
         IconWifi,
         IconMobile,
         IconDesktop,
         formDataPortal,
         selectedTab: 'portals-edit',
         portalOptions: null,
         agreements: null,
         portalProviderOptions: null,
         selectedPreviewType: 'mobile',
         isLoading: false,
         formDataRedirectRules: null,
         formDataSurveys: [],
         dataPortalDetail: SingleResourceResponse.create(),
         tabItems: [
            {
               id: 'portals-edit',
               label: this.$t('Genel'),
            },
            {
               id: 'portals-edit-sign-in-methods',
               label: this.$t('Giriş Metodları'),
            },
            {
               id: 'portals-edit-surveys',
               label: this.$t('Anketler'),
            },
            {
               id: 'portals-edit-advertisements',
               label: this.$t('Reklamlar'),
            },
            {
               id: 'portals-edit-rules',
               label: this.$t('Kurallar'),
            },
            {
               id: 'portals-edit-advanced',
               label: this.$t('Gelişmiş'),
            },
         ],
         previewAppUrl: `${process.env.VUE_APP_PREVIEW_URL}/preview/${this.$props.portalId}?tenant=${getSubdomain()}`,
      };
   },

   async beforeMount() {
      await this.loadPortalDetail();
   },

   methods: {
      async onTabChanged(tabItem) {
         if (this.$route.name === tabItem) return;

         //await this.$router.push({ name: tabItem, params: { ...this.$route.params } });
      },

      async loadPortalDetail() {
         this.isLoading = true;

         try {
            const portalId = this.$props.portalId;

            const { data } = await PanelPortalApi.show(portalId);
            this.dataPortalDetail.merge(data);

            this.formDataPortal.merge(this.dataPortalDetail.only('name'));
            this.formDataRedirectRules = this.dataPortalDetail.get('redirect_rules', []);
            this.formDataSurveys = this.dataPortalDetail.get('surveys', []);
            this.agreements = this.dataPortalDetail.get('agreements', {});

            const dataStyleOptions = this.dataPortalDetail.get('style_options', []);

            const styles = {};
            each(dataStyleOptions, (item) => (styles[item.key] = item.value));

            this.portalOptions = {
               styles: styles,
               messages: this.dataPortalDetail.get('messages', {}),
            };

            this.portalProviderOptions = {
               providers: this.dataPortalDetail.get('providers', []),
               messages: this.dataPortalDetail.get('messages', {}),
            };
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async submitPortalPut() {
         if (!this.formDataPortal.isValid()) return;

         this.isLoading = true;
         try {
            const portalId = this.$props.portalId;
            await PanelPortalApi.update(portalId, this.formDataPortal.toObject());

            Notify.success(this.$t('Portal güncellendi!'));
            await this.loadPortalDetail();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async surveysChanged(newSurveys) {
         this.formDataPortal.surveys = newSurveys;
      },

      async redirectRulesChanged(newRules) {
         this.formDataPortal.merge({ redirect_rules: newRules });
      },

      submitPortalPublish() {},

      setPreviewType(type) {
         this.selectedPreviewType = type;
      },

      isPreviewType(type) {
         return this.selectedPreviewType === type;
      },

      async onAgreementChanged(value) {
         console.log(value);
         this.formDataPortal.merge({ agreements: value });
      },

      async onContentChanged(value) {
         this.formDataPortal.merge({ messages: value });

         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'message_changed',
               value: value,
            },
            '*'
         );
      },

      async onStyleChanged(value) {
         this.formDataPortal.merge({ styles: value });
         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'style_changed',
               value: value,
            },
            '*'
         );
      },
      async onProviderChanged(value) {
         const providers = value.filter((value) => value.checked);
         this.formDataPortal.merge({ providers: providers });

         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'provider_changed',
               value: providers,
            },
            '*'
         );
      },
   },
};
</script>

<style lang="scss">
.obi-pe {
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: stretch;
   height: 100%;

   .obi-pe-pane {
      border-right: 1px solid #e3e5e5;

      &:first-child {
         .pane-header {
            padding-left: 2rem;
         }

         .pane-body {
            margin-left: 0;
            border-radius: 0 0 0 5px;
         }
      }

      &:last-child {
         border-right: 0;

         .pane-header {
            padding-right: 2rem;
         }

         .pane-body {
            margin-right: 2rem;
            border-radius: 0 0 5px 0;
         }
      }

      &.pane-configuration {
         .pane-header {
            border-bottom: 0;
            padding: 0.7rem 0 0;
         }
      }

      &.pane-preview {
         .pane-header {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: auto 1fr auto;

            .pane-actions {
               gap: 1rem;
               display: inline-flex;

               .obi-button {
                  min-width: 100px;
               }
            }
         }

         .pane-body {
            display: grid;
            align-items: center;
            justify-content: center;
         }
      }
   }

   .pane-header {
      padding: 1rem;
      border-bottom: 1px solid #eeeeee;
   }

   .pane-body {
      padding: 1rem 1.5rem;
      background-color: #f1f1f1;
      height: calc(100% - 6.5rem);
   }
}
</style>
